var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BasicCard',{attrs:{"loading":_vm.isBusy,"title":"DID Assignment"},scopedSlots:_vm._u([{key:"feature",fn:function(){return [_c('v-icon',{attrs:{"right":"","x-large":""}},[_vm._v("mdi-link")])]},proxy:true},(_vm.didData.i_master_account != null || _vm.didData.i_customer != null || (_vm.didData.managed_by != null && _vm.$store.state.user.isAdmin))?{key:"actions",fn:function(){return [(_vm.didData.i_master_account != null && _vm.trunkData != null)?_c('ButtonWithTooltip',{attrs:{"text":"Trunk","icon":"mdi-eye","to":{ name: 'TrunkSummary', params: { customerId: _vm.trunkData.i_customer, trunkId: _vm.didData.i_master_account}},"tooltip":"View Trunk Details","flat":""}}):_vm._e(),(_vm.didData.i_customer != null)?_c('ButtonWithTooltip',{attrs:{"text":"Customer","icon":"mdi-eye","to":{
                name: 'CustomerSummary',
                params: { customerId: _vm.didData.i_customer },
            },"tooltip":"View Customer Details","flat":""}}):_vm._e(),(_vm.didData.managed_by != null && _vm.$store.state.user.isAdmin)?_c('ButtonWithTooltip',{attrs:{"text":"Wholesale User","icon":"mdi-eye","to":{
                  name: _vm.$store.state.user.isAdmin ? 'AdminWholesaleHome' : 'WholesaleManagedHome',
                  params: _vm.$store.state.user.isAdmin ? { customerId: _vm.didData.managed_by } : {},
              },"tooltip":"View Customer Details","flat":""}}):_vm._e()]},proxy:true}:null],null,true)},[(_vm.didData.i_master_account != null)?_c('v-alert',{attrs:{"type":"success"}},[_vm._v("This DID is active.")]):(_vm.didData.i_customer != null)?_c('v-alert',{staticClass:"primary",staticStyle:{"color":"#ffffff"}},[_vm._v("DID Reserved for Customer.")]):(_vm.didData.managed_by != null)?_c('v-alert',{staticClass:"primary",staticStyle:{"color":"#ffffff"}},[_vm._v("DID Reserved by Wholesale User.")]):_vm._e(),(_vm.didData.i_master_account != null || _vm.didData.i_customer != null || _vm.didData.managed_by != null)?_c('v-sheet',{staticClass:"px-3 pt-1 text--secondary",class:{
            'blue-grey darken-5': _vm.$vuetify.theme.dark,
            'blue-grey lighten-5': !_vm.$vuetify.theme.dark,
        }},[(!_vm.loading && _vm.trunkData)?_c('p',{staticClass:"subtitle-1 text--primary my-1"},[_vm._v(" "+_vm._s(_vm.trunkData.name)+" ")]):_vm._e(),(_vm.didData.i_master_account != null && _vm.trunkData != null)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Trunk ID")])]),_c('v-col',{attrs:{"cols":"7"}},[_vm._v(" "+_vm._s(_vm.didData.i_master_account)+" ")]),_c('v-col',{attrs:{"cols":"5"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Activated")])]),_c('v-col',{attrs:{"cols":"7"}},[_vm._v(" "+_vm._s(_vm.trunkData.activation_date)+" ")]),_c('v-col',{attrs:{"cols":"5"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Customer")])]),_c('v-col',{attrs:{"cols":"7"}},[_vm._v(" "+_vm._s(_vm.trunkData.customer_name)+" ")])],1):_vm._e(),(_vm.didData.i_customer != null && _vm.customerData != null)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Customer ID")])]),_c('v-col',{attrs:{"cols":"7"}},[_vm._v(" "+_vm._s(_vm.didData.i_customer)+" ")]),_c('v-col',{attrs:{"cols":"5"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Name")])]),_c('v-col',{attrs:{"cols":"7"}},[_vm._v(" "+_vm._s(_vm.customerData.firstname)+" "+_vm._s(_vm.customerData.lastname)+" ")])],1):_vm._e(),(_vm.didData.managed_by != null && _vm.resellerData != null)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Wholesale User ID")])]),_c('v-col',{attrs:{"cols":"7"}},[_vm._v(" "+_vm._s(_vm.didData.managed_by)+" ")]),_c('v-col',{attrs:{"cols":"5"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Name")])]),_c('v-col',{attrs:{"cols":"7"}},[_vm._v(" "+_vm._s(_vm.resellerData.firstname)+" "+_vm._s(_vm.resellerData.lastname)+" ")])],1):_vm._e()],1):_c('v-sheet',{staticClass:"px-3 pt-1 text--secondary",class:{
            'blue-grey darken-5': _vm.$vuetify.theme.dark,
            'blue-grey lighten-5': !_vm.$vuetify.theme.dark,
        }},[_c('p',[_vm._v("This DID is not assigned.")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }