<template>
    <BasicCard :loading="loading" title="DID Subscription">
      <div v-if="didData != null && didData.didSubscription != null">
        <p class="title text--primary mt-1">
          {{didData.didSubscription.description}} - <Currency :value="didData.didSubscription.markup_options.base_periodic_fee" hide-tooltip /> per month
        </p>
        <p v-if="$store.state.user.isAdmin">Name: {{didData.didSubscription.name}}</p>
      </div>
      <div v-else>
        <p>No DID Subscription attached.</p>
      </div>

        <template v-slot:actions>
            <ButtonWithTooltip
                text="Edit"
                icon="mdi-pencil"
                color="primary"
                tooltip="Edit Did subscription"
                flat
                @click="showEditDIDSubscription = true"
            />
        </template>

      <DIDSubscriptionUpdateDialog v-model="showEditDIDSubscription" :didData="didData"/>

    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import Currency from '../../pieces/Currency';
import DIDSubscriptionUpdateDialog from "../../dialogs/DID/DIDSubscriptionUpdateDialog";
export default {
    name: 'DIDSubscriptionCard',
    components: {DIDSubscriptionUpdateDialog, Currency, ButtonWithTooltip, BasicCard },
    props: {
      didData: {
        type: Object,
        default: null,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
        return {
          showDialog: false,
          //didSubscription:null,
          didSubscriptions:[],
          showEditDIDSubscription:false,
        };
    },
    methods: {
      reset( ){
        this.showDialog = false;
        this.didSubscriptions = [];
        this.showEditDIDSubscription = false;
        this.requestDidSubscriptions( );

      },

      closeModal(refresh) {
        this.showDialog = false;
        if (refresh) {
          this.$emit('update');
        }
      },

      async requestDidSubscriptions(){
        this.busy = true;
        const response = await this.Api.send('get', 'wholesalers/' + this.didData.managed_by + '/did-subscriptions?limit=100');
        if(response.success){
          this.didSubscriptions = response.data;
        }

        this.busy = false;
      }
    },
    watch: {
      managedBy(value, oldValue) {
        if(value !== null && value != oldValue){
          this.reset( );
        }
      },
      // iDoBatch(value, oldValue) {
      //   if(value !== null && value != oldValue){
      //     this.reset( );
      //   }
      // },
    },
    computed: {
      managedBy: function () {
        if(this.didData && this.didData.managed_by){
          return this.didData.managed_by;
        }else{
          return null;
        }
      },
      // iDoBatch: function () {
      //   if(this.didData && this.didData.managed_by){
      //     return this.didData.managed_by;
      //   }else{
      //     return null;
      //   }
      // },
    },
};
</script>
