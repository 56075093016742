<template>
  <SimpleDialog
      v-model="visible"
      :title="'Edit DID Subscription for \''+didData.number+'\''"
      :loading="busy"
      :yesText="'Update'"
      :yesDisabled="!valid"
      @click="submit"
      noText="Cancel"
      styledHeader
  >
    <v-form v-model="valid" :disabled="isBusy">
    <SpinnerOverlay absolute v-if="isBusy" />
    <v-row no-gutters>
      <v-col cols="12">
        <v-switch
            inset
            v-model="didSubscriptionToggle"
        >
          <template v-slot:label>
            Assign DID Subscription
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs"
                        v-on="on" v-html="'mdi-help-circle-outline'" />
              </template>
              <span> Link a DID Subscription to the selected DID(s) that will be applied to the Account/Trunk the DID is assigned to. </span>
            </v-tooltip>
          </template>
        </v-switch>

        <v-expand-transition>
          <div v-if="didSubscriptionToggle">
            <v-select
                hide-details
                outlined
                dense
                label="DID Subscription"
                v-model="form.i_do_batch"
                :disabled="isBusy"
                :items="didSubscriptionsOptions"
                item-text="option"
                item-value="value"
            />
          </div>
        </v-expand-transition>
        </v-col>
      </v-row>
    </v-form>
  </SimpleDialog>
</template>

<script>
import dialogMixin from '../../../mixins/dialogMixin';
import apiMixin from '../../../mixins/apiMixin';
import SimpleDialog from "../templates/SimpleDialog";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import SpinnerOverlay from "../../pieces/Global/SpinnerOverlay";
export default {
    name: 'DIDSubscriptionUpdateDialog',
    components: {SimpleDialog,SpinnerOverlay},
    mixins: [apiMixin, dialogMixin, GlobalHelperMixin],
    data: () => ({
      busy: false,
      valid:false,
      didSubscriptionsOptions:[],
      didSubscriptionToggle:false,
      form:{
        i_do_batch:null,
      }
    }),
    props: {
      didData: {
        type: Object,
        default: null
      },
      loading:{
        type: Boolean,
        default:false
      }
    },
    methods: {
      reset( ){
        this.form = {
          i_do_batch:null,
        };
        this.didSubscriptionToggle = false;
        this.requestDidSubscriptions( );
        if(this.didData.didSubscription) {
          this.form.i_do_batch = this.didData.didSubscription.i_do_batch;
          this.didSubscriptionToggle = true;
        }
      },

      // update( ){
      //   this.reset( );
      // },

      async requestDidSubscriptions(){
        this.busy = true;
        const response = await this.Api.send('get', 'wholesalers/' + this.didData.managed_by + '/did-subscriptions?limit=100'); //should never exceed 100 (more like 5)
        if(response.success){
          const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'AUD',
          });
          let self = this;
          response.data.results.forEach(function (item, index) {
            self.didSubscriptionsOptions.push({option: item.description+' - '+formatter.format(item.markup_options.base_periodic_fee), value: item.i_do_batch});
          });
        }
        this.busy = false;
      },

      async submit( ){
        this.busy = true;
        let response;
        if(this.didSubscriptionToggle) {
          response = await this.Api.send('put', 'wholesalers/' + this.didData.managed_by + '/dids/' + this.didData.i_did_number + '?i_do_batch=' + this.form.i_do_batch);
        }else{
          response = await this.Api.send('put', 'wholesalers/' + this.didData.managed_by + '/dids/' + this.didData.i_did_number + '/release-did-subscription');
        }
        if (response.success) {
          this.showGlobalSuccessMessage('Successfully updated DID with DID Subscription.');
          document.dispatchEvent(new CustomEvent('refreshRequested'));
          this.visible = false;
        }
        this.busy = false;
      },
    },
  watch: {
    visible(value) {
      if(value){
        this.reset();
      }
    }
  },
  computed: {
    isBusy() {
      return this.loading || this.busy;
    },
  }
};
</script>