<template>
    <DIDDetail :reseller-id="resellerId" />
</template>

<script>
import DIDDetail from "../did/DIDDetail";
import resellerDataMixin from "../../mixins/resellerDataMixin";
export default {
    name: 'WholesaleManagedDidDetail',
    mixins: [resellerDataMixin],
    components: {
      DIDDetail,
    },
};
</script>
