<template>
    <v-container fluid>
        <v-row dense>
            <!-- non-dismissable alert only shows if status is blocked -->
            <v-col cols="12" v-if="data.frozen === 'Y'">
                <Alert type="error" message="This number is frozen." />
            </v-col>
            <!-- DID Details Card -->
            <v-col cols="12" sm="6" lg="4">
                <DIDCard :data="data" :loading="loading" />
            </v-col>
            <!-- DID Assigment Card -->
            <v-col cols="12" sm="6" lg="4">
                <DIDAssignmentCard
                    :didData="data"
                    :loading="loading"
                />
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <DIDPricing :data="data" :loading="loading" :reseller-id="resellerId"/>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <DIDCarrierCard :data="data" :loading="loading" />
            </v-col>
          <v-col cols="12" sm="6" lg="4">
            <DIDSubscriptionCard :didData="data" :loading="loading" />
          </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Alert from '@/components/pieces/Alert';
import DIDCard from '../../components/cards/DID/DIDCard';
import DIDAssignmentCard from '../../components/cards/DID/DIDAssignmentCard';
import DIDPricing from '../../components/cards/DID/DIDPricing';
import DIDCarrierCard from '../../components/cards/DID/DIDCarrierCard';
import apiMixin from '../../mixins/apiMixin';
import DIDSubscriptionCard from "../../components/cards/DID/DIDSubscriptionCard";
import refreshListenerMixin from "../../mixins/refreshListenerMixin";
export default {
    name: 'DIDDetail',
    mixins: [apiMixin, refreshListenerMixin],
    data: () => ({
        loading: true,
        reloadDIDList: false,
        newDIDAdded: false,
        newDIDAddedSuccess: false,
        data: {
            data: [],
        },
        dialogs: {
            newDID: false,
        },
    }),
    components: {
      DIDSubscriptionCard,
      DIDCarrierCard,
      DIDPricing,
      DIDAssignmentCard,
      DIDCard,
      Alert,
    },
    props: {
      //triggers the web selfcare from the API
      resellerId: {
        type: Number,
        default: null,
      },
    },
    computed: {
        addDIDMessage: function () {
            return this.newDIDAdded && this.newDIDAddedSuccess
                ? 'DIDs are added successfully.'
                : 'There is an error while adding new DIDs. Please try again later.';
        },
    },
    methods: {
      async get(did) {
        this.loading = true;
        let params = {};
        if(this.resellerId != null){
          params.i_reseller = this.resellerId;
        }
        // if(this.$route.name == 'AdminWholesaleManagedDIDDetail'){
        //   params.i_reseller = this.$route.params.customerId;
        // }else if(this.$route.name == 'WholesaleManagedDids'){
        //   params.i_reseller = this.$store.state.user.i_reseller;
        // }
        const response = await this.Api.send('post','dids/' + did + '/info',params);
        this.loading = false;
        if (response.success) {
            this.updateData(response.data);
        } else {
            this.updateFailed(response.error, response.errorDetail);
        }
      },
      update() {
        this.get(this.$route.params.did);
      },
      updateData(data) {
        this.data = data;
      },
      updateFailed() {
        this.data = [];
      },
      savedDID() {
        this.dialogs.newDID = false;
        this.newDIDAdded = true;
        this.newDIDAddedSuccess = true;
        this.reloadDIDList = true;
        this.$nextTick(() => {
            this.reloadDIDList = false;
        });
      },
      addDIDError() {
        this.newDIDAdded = true;
        this.newDIDAddedSuccess = false;
      },
    },
    created() {
      this.update();
    },
};
</script>
