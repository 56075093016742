<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <DIDListCard
                    :loading="loading"
                    :small="$vuetify.breakpoint.xsOnly"
                    :filter="filter"
                    @update="updateFilter"
                    show-filters
                    show-tools
                    @addButtonTriggered="showNewDIDs=true"
                />
            </v-col>
        </v-row>
        <FloatingButton tooltip="Add New DIDs" icon="mdi-phone-plus" @click="showNewDIDs=true" />
        <AddDIDDialog v-model="showNewDIDs" :validOptions="addDidOptions" processTypeSwitch="pool"/>
    </v-container>
</template>

<script>
import DIDListCard from "../../components/cards/DID/DIDListCard";
import filterMixin from '../../mixins/filterMixin';
import FloatingButton from '@/components/pieces/FloatingButton';
import AddDIDDialog from "../../components/dialogs/DID/AddDIDDialog";
export default {
    name: 'DIDInventory',
    mixins: [filterMixin],
    data: () => ({
        filter: {
            usage: 'A',
            number: null,
            page: 1,
            limit: 10,
        },
        filterInput: {
            number: '',
        },
        loading: false,
        showNewDIDs: false,
        addDidOptions: {"admin":[2,3]}
    }),
    components: {
        AddDIDDialog,
        FloatingButton,
        DIDListCard,
    },
    beforeRouteLeave(to, from, next){
        return (this.showNewDIDs) ? next(false) : next();
    }
};
</script>
