<template>
    <BasicCard :loading="isBusy" title="DID Assignment">
        <template v-slot:feature>
            <v-icon right x-large>mdi-link</v-icon>
        </template>

        <v-alert type="success" v-if="didData.i_master_account != null">This DID is active.</v-alert>
        <v-alert class="primary" style="color:#ffffff;" v-else-if="didData.i_customer != null">DID Reserved for Customer.</v-alert>
        <v-alert class="primary" style="color:#ffffff;" v-else-if="didData.managed_by != null">DID Reserved by Wholesale User.</v-alert>

        <v-sheet
            class="px-3 pt-1 text--secondary"
            v-bind:class="{
                'blue-grey darken-5': $vuetify.theme.dark,
                'blue-grey lighten-5': !$vuetify.theme.dark,
            }"
            v-if="didData.i_master_account != null || didData.i_customer != null || didData.managed_by != null"
        >
            <p
                class="subtitle-1 text--primary my-1"
                v-if="!loading && trunkData"
            >
                {{ trunkData.name }}
            </p>

            <v-row dense v-if="didData.i_master_account != null && trunkData != null">
                <v-col cols="5">
                    <span class="font-weight-bold">Trunk ID</span>
                </v-col>
                <v-col cols="7">
                    {{ didData.i_master_account }}
                </v-col>
                <v-col cols="5">
                    <span class="font-weight-bold">Activated</span>
                </v-col>
                <v-col cols="7">
                    {{ trunkData.activation_date }}
                </v-col>
                <v-col cols="5">
                    <span class="font-weight-bold">Customer</span>
                </v-col>
                <v-col cols="7">
                    {{ trunkData.customer_name }}
                </v-col>
            </v-row>
            <v-row dense v-if="didData.i_customer != null && customerData != null">
              <v-col cols="5">
                <span class="font-weight-bold">Customer ID</span>
              </v-col>
              <v-col cols="7">
                {{ didData.i_customer }}
              </v-col>
              <v-col cols="5">
                <span class="font-weight-bold">Name</span>
              </v-col>
              <v-col cols="7">
                {{ customerData.firstname }} {{ customerData.lastname }}
              </v-col>
            </v-row>
            <v-row dense v-if="didData.managed_by != null && resellerData != null">
              <v-col cols="5">
                <span class="font-weight-bold">Wholesale User ID</span>
              </v-col>
              <v-col cols="7">
                {{ didData.managed_by }}
              </v-col>
              <v-col cols="5">
                <span class="font-weight-bold">Name</span>
              </v-col>
              <v-col cols="7">
                {{ resellerData.firstname }} {{ resellerData.lastname }}
              </v-col>
            </v-row>
        </v-sheet>
      <v-sheet
          v-else
          class="px-3 pt-1 text--secondary"
          v-bind:class="{
                'blue-grey darken-5': $vuetify.theme.dark,
                'blue-grey lighten-5': !$vuetify.theme.dark,
            }"
          ><p>This DID is not assigned.</p></v-sheet>

        <template v-slot:actions v-if="didData.i_master_account != null || didData.i_customer != null || (didData.managed_by != null && $store.state.user.isAdmin)">
            <ButtonWithTooltip
                v-if="didData.i_master_account != null && trunkData != null"
                text="Trunk"
                icon="mdi-eye"
                :to="{ name: 'TrunkSummary', params: { customerId: trunkData.i_customer, trunkId: didData.i_master_account}}"
                tooltip="View Trunk Details"
                flat
            />
            <ButtonWithTooltip
                v-if="didData.i_customer != null"
                text="Customer"
                icon="mdi-eye"
                :to="{
                    name: 'CustomerSummary',
                    params: { customerId: didData.i_customer },
                }"
                tooltip="View Customer Details"
                flat
            />
            <ButtonWithTooltip
                v-if="didData.managed_by != null && $store.state.user.isAdmin"
                text="Wholesale User"
                icon="mdi-eye"
                :to="{
                      name: $store.state.user.isAdmin ? 'AdminWholesaleHome' : 'WholesaleManagedHome',
                      params: $store.state.user.isAdmin ? { customerId: didData.managed_by } : {},
                  }"
                tooltip="View Customer Details"
                flat
            />
        </template>
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'DIDAssignmentCard',
    components: { ButtonWithTooltip, BasicCard },
    mixins: [apiMixin],
    props: {
        didData: {
            type: Object,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
      busy: false,
      trunkData: null,
      customerData:null,
      resellerData:null,
    }),
    methods: {
        async getTrunk(id) {
            this.data = [];
            this.busy = true;
            const response = await this.Api.send('get','trunks/' + id);
            this.busy = false;
            if (response.success) {
                this.trunkData = response.data;
            } else {
                this.trunkData = null;
            }
        },
        async getCustomer(id) {
          this.data = [];
          this.busy = true;
          const response = await this.Api.send('get','customers/' + id);
          this.busy = false;
          if (response.success) {
            this.customerData = response.data;
          } else {
            this.customerData = null;
          }
        },
        async getReseller(id) {
          this.data = [];
          this.busy = true;
          const response = await this.Api.send('get','customers/' + id);
          this.busy = false;
          if (response.success) {
            this.resellerData = response.data;
          } else {
            this.resellerData = null;
          }
        },
    },
    watch: {
        // accountId: function (value) {
        //   if(value != null) {
        //     this.getTrunk(value);
        //   }
        // },
        didData: function(value){
          if(value != null){
            if(value.i_master_account != null){
              this.getTrunk(value.i_master_account);
            }else if(value.i_customer != null){
              this.getCustomer(value.i_customer);
            }else if(value.managed_by != null){
              this.getReseller(value.managed_by);
            }
          }
        }
    },
  computed:{
    // accountId( ){
    //   return this.didData.i_master_account ?? null;
    // },
    isBusy( ){
      return this.loading || this.busy;
    }
  },
    created() {
        //this.get(this.accountId);
    },
};
</script>
